<template>
  <div>
    <baidu-map-view ref="baiduMap" :map-data="mapData" />
    <div style="text-align: center">
      <div style="font-size: 32px; margin-top: 30px">
        {{ current_DateTime }}
      </div>
      <div style="font-size: 16px; color: grey; margin-top: 15px">
        {{ shop_Name }}
      </div>
      <div
        style="height: 20px; font-size: 16px; margin-top: 15px"
        :style="{ color: msgColor }"
      >
        {{ msg }}
      </div>
      <div style="margin-top: 15px">
        <button
          id="signIn"
          class="btn"
          :disabled="buttonStatus"
          :style="{ 'background-color': buttonColor }"
          @click="signIn"
        >
          <span style="color: #fff; font-size: 25px">打卡</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import { Dialog, Toast } from 'vant'
  import BaiduMapView from '@/components/BaiduMapView.vue'
  import {
    getDpDZxx,
    DDID_ZDDP,
    Shop_DK,
  } from '@/api/workBench/attendance/attendance'
  import {
    getGeolocation,
    startGeolocation,
    stopGeolocation,
  } from '@/util/jsApi'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Attendance',
    components: { BaiduMapView },
    data() {
      return {
        street_ID: null,
        street_Name: '',
        shop_ID: null,
        shop_Name: '',
        current_DateTime: '',
        distance: 100,
        mapData: {
          data: [
            { lng: 0, lat: 0 },
            { lng: 0, lat: 0 },
          ],
          mapType: 3,
          showButton: false,
          height: '300px',
        },
        cell_ID: null,
        person_ID: null,
        dateTimeTimeout: null,
        buttonStatus: true,
        msg: '当前所在位置不在该店铺考勤范围内',
        msgColor: 'red',
        buttonColor: '#B3B3B3',
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.cell_ID = teamInfo.Cell_ID
      this.person_ID = userInfo.User_ID
      this.street_ID = this.$route.query.street_ID
      this.current_DateTime = moment().format('YYYY-MM-DD HH:mm:ss')
      if (this.dateTimeTimeout !== null) {
        clearTimeout(this.dateTimeTimeout)
      } else {
        this.dateTimeTimeout = this.updateDateTime()
      }

      await this.setShopInfo()
      await this.initLocation()
      await this.checkStreetInfo()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'attendance',
        '城管-扫码考勤',
        'zzdcg.yy.gov.cn/attendance'
      )
    },
    destroyed() {
      stopGeolocation('attendance')
    },
    methods: {
      //设备定位点初始化
      initLocation() {
        let that = this
        getGeolocation(function(res) {
          let point = that.$refs['baiduMap'].Convert_GCJ02_To_BD09({
            lng: res.longitude,
            lat: res.latitude,
          })
          that.mapData.data[0].lat = point.lat
          that.mapData.data[0].lng = point.lng
          that.$refs['baiduMap'].render()
          that.checkDistance()
        })
      },

      //设置考勤店铺
      async setShopInfo() {
        const { data } = await DDID_ZDDP({
          Street_ID: this.street_ID,
          Cell_ID: this.cell_ID,
        })
        let msg = ''
        switch (data.MSG) {
          case '1':
            this.mapData.data[1].lat = data.dplat
            this.mapData.data[1].lng = data.dplon
            this.distance = data.dpjl
            break
          case '0':
            msg = '该店铺不是重点考勤店铺！'
            break
          case '3':
            msg = '不在考勤时间！'
            break
          default:
            msg = '此账号无法对该店铺进行考勤！'
        }
        if (data.MSG != '1') {
          await Dialog.alert({
            message: msg,
          })
          await this.$router.push('/index')
        }

        this.$refs['baiduMap'].render()
      },

      //获取地址绑定店铺信息,有绑定信息则开启实时定位
      async checkStreetInfo() {
        const { data } = await getDpDZxx({ Street_ID: this.street_ID })
        if (data.MSG === '1') {
          this.street_Name = data.Street_Name
          this.shop_ID = data.Shop_ID
          this.shop_Name = data.Shop_Name

          this.startGeolocation()
        } else {
          await Dialog.alert({
            message: '当前地址未绑定任何店铺',
          })
          await this.$router.push('/index')
        }
      },

      //实时定位
      startGeolocation() {
        let that = this
        startGeolocation('attendance', function(res) {
          let point = that.$refs['baiduMap'].Convert_GCJ02_To_BD09({
            lng: res.longitude,
            lat: res.latitude,
          })
          that.mapData.data[0].lat = point.lat
          that.mapData.data[0].lng = point.lng

          that.$refs['baiduMap'].render()
          that.checkDistance()

          Toast('实时定位成功！')
        })
      },

      //更新时间
      updateDateTime() {
        return setTimeout(() => {
          this.current_DateTime = moment().format('YYYY-MM-DD HH:mm:ss')
          this.dateTimeTimeout = this.updateDateTime()
        }, 1000)
      },

      //检查是否在打卡范围内
      checkDistance() {
        if (this.$refs['baiduMap'] === undefined) return
        const distance = this.$refs['baiduMap'].getDistance(
          this.mapData.data[0],
          this.mapData.data[1]
        )
        if (this.distance >= parseFloat(distance)) {
          this.msg = '已在打卡位置范围内'
          this.msgColor = 'green'
          this.buttonStatus = false
          this.buttonColor = '#22B276'
        } else {
          this.msg = '当前所在位置不在该店铺考勤范围内'
          this.msgColor = 'red'
          this.buttonStatus = true
          this.buttonColor = '#B3B3B3'
        }
      },

      //打卡考勤
      async signIn() {
        const { data } = await Shop_DK({
          Street_ID: this.street_ID,
          Person_ID: this.person_ID,
        })

        let msg = ''
        switch (data.MSG) {
          case '1':
            msg = '重点店铺考勤成功！'
            break
          case '2':
            msg = '当前不在考勤时间段内！'
            break
          case '0':
            msg = '当前时间段已考勤，请勿重复操作！'
            break
          default:
            msg = '网络原因请稍后进行重试!'
        }
        await Dialog.alert({
          message: msg,
        })
        await this.$router.push('/index')
      },
    },
  }
</script>

<style scoped>
  .btn {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: green;
    border: 1px solid #b3b3b3;
  }
</style>
